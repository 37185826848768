import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { baseUrl, playerStatisticsApi } from "../api/api";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "./Loader";
import { useNavigate } from "react-router-dom";
import classes from "./PlayerInfoModal.module.css";
import { resetPlayeModal } from "../slices/playerModal";
import { Dialog } from "primereact/dialog";
import { Panel } from "primereact/panel";
import { TabPanel, TabView } from "primereact/tabview";

export default function PlayerInfoModal() {
  const { open, id } = useSelector((state) => state.playerModal);
  const [loading, setLoading] = useState(true);
  const [player, setPlayer] = useState();
  const [statistics, setStatistics] = useState([]);
  const { lang } = useSelector((state) => state.langSlice);

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(resetPlayeModal());
  };

  const fetchDataFromBackend = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`${baseUrl}${playerStatisticsApi}${id}`);
      console.log(res?.data, "res");
      setPlayer(res?.data?.response?.player);
      setStatistics(res?.data?.response?.statistics);
      setLoading(false);
    } catch (error) {
      console.log(error, "error");
      setLoading(false);
      toast.error(
        error?.data?.message || error?.message || "Something went wrong..."
      );
      dispatch(resetPlayeModal());
    }
  };

  useEffect(() => {
    if (open) {
      fetchDataFromBackend();
    }
  }, [id, open]);

  return (
    <>
      <Dialog
        header={
          lang == "english"
            ? "Player Info"
            : lang == "spanish"
            ? "Información del jugador"
            : lang == "french"
            ? "Informations sur le joueur"
            : lang == "arabic"
            ? "معلومات اللاعب"
            : "Player Info"
        }
        visible={open}
        maximizable
        style={{ width: "100%" }}
        // style={{ width: "400px" }}
        onHide={() => handleClose()}
      >
        {loading ? (
          <Loader />
        ) : (
          <div className={classes.container}>
            <div className={classes.image_container}>
              <img
                src={player?.photo}
                alt={player?.name}
                className={classes.avatar}
              />
            </div>

            <TabView scrollable>
              <TabPanel
                header={
                  lang == "english"
                    ? "Details"
                    : lang == "spanish"
                    ? "Detalles"
                    : lang == "french"
                    ? "Détails"
                    : lang == "arabic"
                    ? "تفاصيل"
                    : "Details"
                }
              >
                <Panel
                  header={
                    lang == "english"
                      ? "Details"
                      : lang == "spanish"
                      ? "Detalles"
                      : lang == "french"
                      ? "Détails"
                      : lang == "arabic"
                      ? "تفاصيل"
                      : "Details"
                  }
                >
                  <div className={classes.player_info_container}>
                    <h1 className={classes.text}>
                      {lang == "english"
                        ? "Name :"
                        : lang == "spanish"
                        ? "Nombre :"
                        : lang == "french"
                        ? "Nom :"
                        : lang == "arabic"
                        ? "اسم :"
                        : "Name :"}{" "}
                      {player?.firstname} {player?.lastname}
                    </h1>
                    <h1 className={classes.text}>
                      {lang == "english"
                        ? "Age :"
                        : lang == "spanish"
                        ? "Edad :"
                        : lang == "french"
                        ? "Âge :"
                        : lang == "arabic"
                        ? "عمر :"
                        : "Age :"}{" "}
                      {player?.age}
                    </h1>
                    <h1 className={classes.text}>
                      {lang == "english"
                        ? "Age :"
                        : lang == "spanish"
                        ? "Edad :"
                        : lang == "french"
                        ? "Âge :"
                        : lang == "arabic"
                        ? "عمر :"
                        : "Age :"}{" "}
                      {player?.nationality}
                    </h1>
                    <h1 className={classes.text}>
                      {lang == "english"
                        ? "Current Team :"
                        : lang == "spanish"
                        ? "Equipo actual :"
                        : lang == "french"
                        ? "Équipe actuelle :"
                        : lang == "arabic"
                        ? "الفريق الحالي :"
                        : "Current Team :"}{" "}
                      {statistics[0]?.team?.name}
                    </h1>
                    <h1 className={classes.text}>
                      {lang == "english"
                        ? "Position :"
                        : lang == "spanish"
                        ? "Posición :"
                        : lang == "french"
                        ? "Position :"
                        : lang == "arabic"
                        ? "موضع :"
                        : "Position :"}{" "}
                      {statistics[0]?.games?.position == null
                        ? 0
                        : statistics[0]?.games?.position}
                    </h1>
                  </div>
                </Panel>
              </TabPanel>
              <TabPanel
                header={
                  lang == "english"
                    ? "Stats I"
                    : lang == "spanish"
                    ? "Estadísticas I"
                    : lang == "french"
                    ? "Statistiques I"
                    : lang == "arabic"
                    ? "إحصائيات أنا"
                    : "Stats I"
                }
              >
                <Panel
                  header={
                    lang == "english"
                      ? "Stats I"
                      : lang == "spanish"
                      ? "Estadísticas I"
                      : lang == "french"
                      ? "Statistiques I"
                      : lang == "arabic"
                      ? "إحصائيات أنا"
                      : "Stats I"
                  }
                >
                  <div className={classes.player_info_container}>
                    <h1 className={classes.text}>
                      {lang == "english"
                        ? "Games Played :"
                        : lang == "spanish"
                        ? "Juegos jugados :"
                        : lang == "french"
                        ? "Parties jouées :"
                        : lang == "arabic"
                        ? "الألعاب التي تم لعبها:"
                        : "Position :"}{" "}
                      {statistics[0]?.games?.appearences == null
                        ? 0
                        : statistics[0]?.games?.appearences}
                    </h1>
                    <h1 className={classes.text}>
                      {lang == "english"
                        ? "Goals Done :"
                        : lang == "spanish"
                        ? "Objetivos cumplidos:"
                        : lang == "french"
                        ? "Objectifs atteints :"
                        : lang == "arabic"
                        ? "تم تحقيق الأهداف :"
                        : "Goals Done :"}{" "}
                      {statistics[0]?.goals?.total == null
                        ? 0
                        : statistics[0]?.goals?.total}
                    </h1>
                    <h1 className={classes.text}>
                      {lang == "english"
                        ? "Goals Conceded :"
                        : lang == "spanish"
                        ? "Objetivos concedidos :"
                        : lang == "french"
                        ? "Buts encaissés :"
                        : lang == "arabic"
                        ? "الأهداف قبلت :"
                        : "Goals Conceded :"}{" "}
                      {statistics[0]?.goals?.conceded == null
                        ? 0
                        : statistics[0]?.goals?.conceded}
                    </h1>
                    <h1 className={classes.text}>
                      {lang == "english"
                        ? "Goals Saved :"
                        : lang == "spanish"
                        ? "Metas guardadas:"
                        : lang == "french"
                        ? "Objectifs sauvegardés :"
                        : lang == "arabic"
                        ? "الأهداف المحفوظة :"
                        : "Goals Saved :"}{" "}
                      {statistics[0]?.goals?.saves == null
                        ? 0
                        : statistics[0]?.goals?.saves}
                    </h1>
                    <h1 className={classes.text}>
                      {lang == "english"
                        ? "Assists :"
                        : lang == "spanish"
                        ? "Asistencias:"
                        : lang == "french"
                        ? "Aides :"
                        : lang == "arabic"
                        ? "يساعد :"
                        : "Assists :"}{" "}
                      {statistics[0]?.goals?.assists == null
                        ? 0
                        : statistics[0]?.goals?.assists}
                    </h1>
                    <h1 className={classes.text}>
                      {lang == "english"
                        ? "Minutes :"
                        : lang == "spanish"
                        ? "Minutos :"
                        : lang == "french"
                        ? "Minutes :"
                        : lang == "arabic"
                        ? "دقائق :"
                        : "Minutes :"}{" "}
                      {statistics[0]?.games?.minutes == null
                        ? 0
                        : statistics[0]?.games?.minutes}
                    </h1>
                    <h1 className={classes.text}>
                      {lang == "english"
                        ? "Rating :"
                        : lang == "spanish"
                        ? "Clasificación:"
                        : lang == "french"
                        ? "Notation :"
                        : lang == "arabic"
                        ? "دقائق :"
                        : "Rating :"}{" "}
                      {statistics[0]?.games?.minutes == null
                        ? 0
                        : statistics[0]?.games?.minutes}
                    </h1>
                  </div>
                </Panel>
              </TabPanel>
              <TabPanel
                header={
                  lang == "english"
                    ? "Stats II"
                    : lang == "spanish"
                    ? "Estadísticas II"
                    : lang == "french"
                    ? "Statistiques II"
                    : lang == "arabic"
                    ? "الإحصائيات II"
                    : "Stats II"
                }
              >
                <Panel
                  header={
                    lang == "english"
                      ? "Stats II"
                      : lang == "spanish"
                      ? "Estadísticas II"
                      : lang == "french"
                      ? "Statistiques II"
                      : lang == "arabic"
                      ? "الإحصائيات II"
                      : "Stats II"
                  }
                >
                  <div className={classes.player_info_container}>
                    <h1 className={classes.text}>
                      {lang == "english"
                        ? "Total Tackles :"
                        : lang == "spanish"
                        ? "Entradas totales:"
                        : lang == "french"
                        ? "Total des plaquages :"
                        : lang == "arabic"
                        ? "إجمالي التدخلات :"
                        : "Total Tackles :"}{" "}
                      {statistics[0]?.tackles?.total == null
                        ? 0
                        : statistics[0]?.tackles?.total}
                    </h1>
                    <h1 className={classes.text}>
                      {lang == "english"
                        ? "Blocks :"
                        : lang == "spanish"
                        ? "Bloques:"
                        : lang == "french"
                        ? "Blocs :"
                        : lang == "arabic"
                        ? "كتل :"
                        : "Blocks :"}{" "}
                      {statistics[0]?.tackles?.blocks == null
                        ? 0
                        : statistics[0]?.tackles?.blocks}
                    </h1>
                    <h1 className={classes.text}>
                      {lang == "english"
                        ? "Interceptions :"
                        : lang == "spanish"
                        ? "Intercepciones:"
                        : lang == "french"
                        ? "Interceptions :"
                        : lang == "arabic"
                        ? "الاعتراضات :"
                        : "Interceptions :"}{" "}
                      {statistics[0]?.tackles?.interceptions == null
                        ? 0
                        : statistics[0]?.tackles?.interceptions}
                    </h1>
                    <h1 className={classes.text}>
                      {lang == "english"
                        ? "Dribbles :"
                        : lang == "spanish"
                        ? "Regates:"
                        : lang == "french"
                        ? "Dribbles :"
                        : lang == "arabic"
                        ? "المراوغات :"
                        : "Dribbles :"}{" "}
                      {statistics[0]?.dribbles?.attempts == null
                        ? 0
                        : statistics[0]?.dribbles?.attempts}
                    </h1>
                    <h1 className={classes.text}>
                      {lang == "english"
                        ? "Dribbles Success :"
                        : lang == "spanish"
                        ? "Regates exitosos:"
                        : lang == "french"
                        ? "Succès des dribbles :"
                        : lang == "arabic"
                        ? "نجاح المراوغات :"
                        : "Dribbles Success :"}{" "}
                      {statistics[0]?.dribbles?.success == null
                        ? 0
                        : statistics[0]?.dribbles?.success}
                    </h1>
                  </div>
                </Panel>
              </TabPanel>
              <TabPanel
                header={
                  lang == "english"
                    ? "Stats III"
                    : lang == "spanish"
                    ? "Estadísticas III"
                    : lang == "french"
                    ? "Statistiques III"
                    : lang == "arabic"
                    ? "الإحصائيات الثالث"
                    : "Stats III"
                }
              >
                <Panel
                  header={
                    lang == "english"
                      ? "Stats III"
                      : lang == "spanish"
                      ? "Estadísticas III"
                      : lang == "french"
                      ? "Statistiques III"
                      : lang == "arabic"
                      ? "الإحصائيات الثالث"
                      : "Stats III"
                  }
                >
                  <div className={classes.player_info_container}>
                    <h1 className={classes.text}>
                      {lang == "english"
                        ? "Total Shots :"
                        : lang == "spanish"
                        ? "Disparos totales :"
                        : lang == "french"
                        ? "Total des tirs :"
                        : lang == "arabic"
                        ? "مجموع الطلقات :"
                        : "Total Shots :"}{" "}
                      {statistics[0]?.shots?.total == null
                        ? 0
                        : statistics[0]?.shots?.total}
                    </h1>
                    <h1 className={classes.text}>
                      {lang == "english"
                        ? "Shots on Goal :"
                        : lang == "spanish"
                        ? "Tiros a puerta :"
                        : lang == "french"
                        ? "Tirs au but :"
                        : lang == "arabic"
                        ? "تسديدة على المرمى :"
                        : "Shots on Goal :"}{" "}
                      {statistics[0]?.shots?.on == null
                        ? 0
                        : statistics[0]?.shots?.on}
                    </h1>
                    <h1 className={classes.text}>
                      {lang == "english"
                        ? "Total Fouls :"
                        : lang == "spanish"
                        ? "Total de faltas:"
                        : lang == "french"
                        ? "Total des fautes :"
                        : lang == "arabic"
                        ? "إجمالي الأخطاء :"
                        : "Total Fouls :"}{" "}
                      {statistics[0]?.fouls?.committed == null
                        ? 0
                        : statistics[0]?.fouls?.committed}
                    </h1>
                    <h1 className={classes.text}>
                      {lang == "english"
                        ? "Yellow Card :"
                        : lang == "spanish"
                        ? "Tarjeta amarilla :"
                        : lang == "french"
                        ? "Carte jaune :"
                        : lang == "arabic"
                        ? "بطاقة صفراء :"
                        : "Yellow Card :"}{" "}
                      {statistics[0]?.cards?.yellow == null
                        ? 0
                        : statistics[0]?.cards?.yellow}
                    </h1>
                    <h1 className={classes.text}>
                      {lang == "english"
                        ? "Red Card :"
                        : lang == "spanish"
                        ? "Tarjeta roja :"
                        : lang == "french"
                        ? "Carte rouge :"
                        : lang == "arabic"
                        ? "البطاقة الحمراء :"
                        : "Red Card :"}{" "}
                      {statistics[0]?.cards?.red == null
                        ? 0
                        : statistics[0]?.cards?.red}
                    </h1>
                  </div>
                </Panel>
              </TabPanel>
            </TabView>
          </div>
        )}
      </Dialog>
    </>
  );
}
