import React from "react";
import classes from "./LastMatchesResults.module.css";
import { Panel } from "primereact/panel";
import { useSelector } from "react-redux";

const LastMatchesResults = ({ team_name, data }) => {
  const { lang } = useSelector((state) => state.langSlice);
  return (
    <Panel header={team_name}>
      <div className={classes.container}>
        {data?.map((dataItem) => {
          return (
            <>
              {dataItem == "W" ? (
                <div className={classes.win}>
                  {" "}
                  {lang == "english"
                    ? "W"
                    : lang == "spanish"
                    ? "W"
                    : lang == "french"
                    ? "W"
                    : lang == "arabic"
                    ? "دبليو"
                    : "W"}{" "}
                </div>
              ) : dataItem == "L" ? (
                <div className={classes.lose}>
                  {" "}
                  {lang == "english"
                    ? "L"
                    : lang == "spanish"
                    ? "L"
                    : lang == "french"
                    ? "L"
                    : lang == "arabic"
                    ? "ل"
                    : "L"}{" "}
                </div>
              ) : (
                <div className={classes.draw}>
                  {" "}
                  {lang == "english"
                    ? "D"
                    : lang == "spanish"
                    ? "D"
                    : lang == "french"
                    ? "D"
                    : lang == "arabic"
                    ? "د"
                    : "D"}{" "}
                </div>
              )}
            </>
          );
        })}
      </div>
    </Panel>
  );
};

export default LastMatchesResults;
