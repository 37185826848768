import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  contestModal: false,
};

const contestModalSlice = createSlice({
  name: "contestModalSlice",
  initialState,
  reducers: {
    setContestModal: (state, action) => {
      state.contestModal = true;
      return state;
    },
    resetContestModal: (state,action) => {
      state.contestModal = false;
      return state;
    },
  },
});

export const { setContestModal,resetContestModal } = contestModalSlice.actions;
export default contestModalSlice;
