import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  notification: 0,
};

const notificationSlice = createSlice({
  name: "notificationSlice",
  initialState,
  reducers: {
    setNotification: (state, action) => {
      state.notification = action.payload;
      return state;
    },
  },
});

export const { setNotification} = notificationSlice.actions;
export default notificationSlice;
