import React, { useEffect, useState } from "react";
import classes from "./Layout.module.css";
import { useSelector, useDispatch } from "react-redux";
import { setDrawer, resetDrawer } from "../slices/drawerSlice";
import { Link, useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import logoAnimation from "../animations/logo.json";
import { Button } from "primereact/button";
import { logoutUser } from "../slices/userSlice";
import { toast } from "react-toastify";
import axios from "axios";
import { baseUrl, logoutApi, userNotificationsCheckApi } from "../api/api";
import MenuTabs from "./MenuTabs";
import Menu from "./Menu";
import { setNotification } from "../slices/notificationSlice";

const Layout = ({ children, active, hideMenu }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { notification } = useSelector((state) => state?.notificationSlice);

  const { drawer } = useSelector((state) => state.drawerSlice);

  const { user } = useSelector((state) => state?.userSlice);

  const { lang } = useSelector((state) => state.langSlice);

  const handleDrawer = (e) => {
    e.preventDefault();
    if (drawer) {
      dispatch(resetDrawer());
    } else {
      dispatch(setDrawer());
    }
  };

  const navigateHandler = (path) => {
    navigate(path);
  };

  const logoutHandler = async () => {
    try {
      const res = await axios.get(`${baseUrl}${logoutApi}`);
      console.log(res, "res");
      dispatch(logoutUser());
      toast.success(
        lang == "english"
          ? "Logout Successfully!"
          : lang == "spanish"
          ? "¡Cerrar sesión correctamente!"
          : lang == "french"
          ? "Déconnexion réussie !"
          : lang == "arabic"
          ? "تسجيل الخروج بنجاح!"
          : "Logout Successfully!"
      );
      navigate("/");
    } catch (error) {
      dispatch(logoutUser());
    }
  };

  const hitApiToCheckNotifications = async (user_id) => {
    try {
      const response = await axios.post(
        `${baseUrl}${userNotificationsCheckApi}`,
        {
          user_id,
        },
        {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        }
      );
      dispatch(setNotification(response.data.result[0]?.COUNT));
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error
      );
    }
  };

  useEffect(() => {
    if (user?.token) {
      hitApiToCheckNotifications(user?.id);
    }
    const intervalId = setInterval(() => {
      if (!document.hidden && user?.token) {
        hitApiToCheckNotifications(user?.id);
      }
    }, 20000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.sidebar}>
        <div className={classes.sidebar_sub_container}>
          <div className={classes.menu_container}>
            <div
              className={`${classes.menu_btn} ${active == 1 && classes.active}`}
              onClick={() => navigateHandler("/")}
            >
              <img
                src="/assets/images/live.png"
                className={classes.menu_asset}
                alt="home"
              />
              {lang == "english"
                ? "Home"
                : lang == "spanish"
                ? "Hogar"
                : lang == "french"
                ? "Maison"
                : lang == "arabic"
                ? "بيت"
                : "Home"}
            </div>
            <div
              className={`${classes.menu_btn} ${active == 2 && classes.active}`}
              onClick={() => navigateHandler("/recent/matches")}
            >
              <img
                src="/assets/images/recent.png"
                className={classes.menu_asset}
                alt="recent"
              />

              {lang == "english"
                ? "Recent"
                : lang == "spanish"
                ? "Reciente"
                : lang == "french"
                ? "Récent"
                : lang == "arabic"
                ? "مؤخرًا"
                : "Recent"}
            </div>
            <div
              className={`${classes.menu_btn} ${active == 3 && classes.active}`}
              onClick={() => navigateHandler("/upcoming/matches")}
            >
              <img
                src="/assets/images/upcoming.png"
                className={classes.menu_asset}
                alt="upcoming"
              />
              {lang == "english"
                ? "Upcoming"
                : lang == "spanish"
                ? "Próximo"
                : lang == "french"
                ? "A venir"
                : lang == "arabic"
                ? "القادمة"
                : "Upcoming"}
            </div>
            <div
              className={`${classes.menu_btn} ${active == 5 && classes.active}`}
              onClick={() => navigateHandler("/team/standings")}
            >
              <img
                src="/assets/images/all.png"
                className={classes.menu_asset}
                alt="standings"
              />
              {lang == "english"
                ? "Standings"
                : lang == "spanish"
                ? "Clasificación"
                : lang == "french"
                ? "Classement"
                : lang == "arabic"
                ? "الترتيب"
                : "Standings"}
            </div>
            <div
              className={`${classes.menu_btn} ${active == 6 && classes.active}`}
              onClick={() => navigateHandler("/match/search")}
            >
              <img
                src="/assets/images/search.png"
                className={classes.menu_asset}
                alt="search"
              />
              {lang == "english"
                ? "Search"
                : lang == "spanish"
                ? "Buscar"
                : lang == "french"
                ? "Recherche"
                : lang == "arabic"
                ? "يبحث"
                : "Search"}
            </div>
          </div>
        </div>
      </div>
      <div className={classes.main}>
        <div className={classes.header}>
          <div className={classes.header_items}>
            <div
              className={classes.logo_container}
              onClick={() => navigateHandler("/")}
            >
              <div className={classes.logo_image_container}>
                <Lottie
                  animationData={logoAnimation}
                  loop={true}
                  autoplay={true}
                  className={classes.logo}
                />
              </div>
              <h1 className={classes.logo_text}>
                {/* GoalAlert */}
                Kickwinn
              </h1>
            </div>
            <div className={classes.menu_icon}>
              <label for="check" onClick={handleDrawer}>
                <input
                  type="checkbox"
                  id="check"
                  checked={drawer}
                  onChange={(e) => console.log(e.target.checked)}
                />
                <span className={classes.menu_span}></span>
                <span className={classes.menu_span}></span>
                <span className={classes.menu_span}></span>
              </label>
            </div>
          </div>
        </div>
        {!hideMenu && <Menu />}

        {children}
      </div>
    </div>
  );
};

export default Layout;
