import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import HomePage from "../pages/HomePage";
import UpcomingPage from "../pages/UpcomingPage";
import MatchPage from "../pages/MatchPage";
import StandingsPage from "../pages/StandingsPage";
import SearchPage from "../pages/SearchPage";
import LoginPage from "../pages/LoginPage";
import SignupPage from "../pages/SignupPage";
import FantasyGamePage from "../pages/FantasyGamePage";
import MatchContestsPage from "../pages/MatchContestsPage";
import CreateTeamPage from "../pages/CreateTeamPage";
import CreatedTeamPage from "../pages/CreatedTeamPage";
import UserMatchesPage from "../pages/UserMatchesPage";
import PointsPage from "../pages/PointsPage";
import Auth from "../components/auth/Auth";
import RecentPage from "../pages/RecentPage";
import PageNotFound from "../pages/PageNotFound";
import NotificationsPage from "../pages/NotificationsPage";

const Routing = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <HomePage />,
    },
    {
      path: "/recent/matches",
      element: <RecentPage />,
    },
    {
      path: "/upcoming/matches",
      element: <UpcomingPage />,
    },
    {
      path: "/match/:id/:homeTeamId/:awayTeamId",
      element: <MatchPage />,
    },
    {
      path: "/team/standings",
      element: <StandingsPage />,
    },
    {
      path: "/match/search",
      element: <SearchPage />,
    },
    // {
    //   path: "/login",
    //   element: <LoginPage />,
    // },
    // {
    //   path: "/signup",
    //   element: <SignupPage />,
    // },
    // {
    //   path: "/fantasy/game",
    //   element: <FantasyGamePage />,
    // },
    // {
    //   path: "/match/contests/:id/:homeTeamId/:awayTeamId",
    //   element: (
    //     <Auth>
    //       <MatchContestsPage />
    //     </Auth>
    //   ),
    // },
    // {
    //   path: "/match/create-team/:id/:homeTeamId/:awayTeamId/:contestId",
    //   element: (
    //     <Auth>
    //       <CreateTeamPage />
    //     </Auth>
    //   ),
    // },
    // {
    //   path: "/match/created-team/:id/:homeTeamId/:awayTeamId/:contestId",
    //   element: (
    //     <Auth>
    //       <CreatedTeamPage />
    //     </Auth>
    //   ),
    // },
    // {
    //   path: "/user/matches",
    //   element: <UserMatchesPage />,
    // },
    // {
    //   path: "/user/points",
    //   element: <PointsPage />,
    // },
    // {
    //   path: "/notifications",
    //   element: <NotificationsPage />,
    // },
    {
      path: "*",
      element: <PageNotFound />,
    },
  ]);
  return <RouterProvider router={router} />;
};

export default Routing;
