import React, { useEffect } from "react";
import classes from "./Commentry.module.css";
import OwlCarousel from "react-owl-carousel";
import { useSelector } from "react-redux";

const Commentry = ({ events }) => {
  const { lang } = useSelector((state) => state.langSlice);
  const options = {
    loop: true,
    items: 3,
    margin: 0,
    margin: 20,
    center: true,
    nav: true,
    navText: [
      "<div class='nav-button owl-prev'><i class='pi pi-arrow-left'></i></div>",
      "<div class='nav-button owl-next'><i class='pi pi-arrow-right'></i></div>",
    ],
    autoplay: true,
    dots: false,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    smartSpeed: 450,
    responsive: {
      0: {
        items: 1,
      },
      300: {
        items: 1,
      },
      500: {
        items: 1,
      },
      700: {
        items: 1,
      },
      800: {
        items: 3,
      },
    },
  };
  return (
    <div className={classes.carousel_container}>
      <div className={classes.sub_container}>
        <OwlCarousel className={`${classes.carousel} owl-theme`} {...options}>
          {events?.map((data, i) => {
            return (
              <div key={i} className={classes.carousel_item}>
                <p>
                  {lang == "english"
                    ? "Time :"
                    : lang == "spanish"
                    ? "Tiempo :"
                    : lang == "french"
                    ? "Temps :"
                    : lang == "arabic"
                    ? "وقت :"
                    : "Time :"}{" "}
                  {data?.time?.elapsed}`
                </p>
                <p>
                  {lang == "english"
                    ? "Team :"
                    : lang == "spanish"
                    ? "Equipo :"
                    : lang == "french"
                    ? "Équipe :"
                    : lang == "arabic"
                    ? "فريق :"
                    : "Team :"}{" "}
                  {data?.team?.name}
                </p>
                <p>
                  {lang == "english"
                    ? "Detail :"
                    : lang == "spanish"
                    ? "Detalle :"
                    : lang == "french"
                    ? "Détail :"
                    : lang == "arabic"
                    ? "التفاصيل :"
                    : "Detail :"}{" "}
                  {data?.detail}
                </p>
                <p>
                  {data?.detail.includes("Substitution") ? (
                    <>
                      <i
                        className="pi pi-arrow-circle-left"
                        style={{ color: "red" }}
                      ></i>{" "}
                      : {data?.player?.name}
                    </>
                  ) : (
                    `
                    ${
                      lang == "english"
                        ? "Player :"
                        : lang == "spanish"
                        ? "Jugador:"
                        : lang == "french"
                        ? "Joueur :"
                        : lang == "arabic"
                        ? "لاعب :"
                        : "Player :"
                    }
                    ${data?.player?.name}`
                  )}
                </p>
                <p>
                  {data?.detail.includes("Substitution") && (
                    <>
                      <i
                        className="pi pi-arrow-circle-right"
                        style={{ color: "green" }}
                      ></i>{" "}
                      : {data?.assist?.name}
                    </>
                  )}
                </p>
              </div>
            );
          })}
        </OwlCarousel>
      </div>
    </div>
  );
};

export default Commentry;
