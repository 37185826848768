import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  open: false,
  id: null,
};

const playerModal = createSlice({
  name: "playerModal",
  initialState,
  reducers: {
    setPlayerModal: (state, action) => {
      state.open = true;
      state.id = action.payload;
      return state;
    },
    resetPlayeModal: (state, action) => {
      state.open = false;
      state.id = null;
      return state;
    },
  },
});

export const { setPlayerModal, resetPlayeModal } = playerModal.actions;
export default playerModal;
