import { configureStore } from "@reduxjs/toolkit";
import drawerSlice from "./slices/drawerSlice";
import editModalSlice from "./slices/editModalSlice";
import contestModalSlice from "./slices/contestModalSlice";
import playerModal from "./slices/playerModal";
import userSlice from "./slices/userSlice";
import navbarPlayersSlice from "./slices/navbarPlayersSlice";
import userTeamSlice from "./slices/userTeamSlice";
import langSlice from "./slices/langSlice";
import notificationSlice from "./slices/notificationSlice";

const store=configureStore({
    reducer:{    
       drawerSlice:drawerSlice.reducer,
       editModalSlice:editModalSlice.reducer,
       contestModalSlice:contestModalSlice.reducer,
       playerModal:playerModal.reducer,
       navbarPlayersSlice:navbarPlayersSlice.reducer,
       userTeamSlice:userTeamSlice.reducer,
       userSlice:userSlice.reducer,
       langSlice:langSlice.reducer,
       notificationSlice:notificationSlice.reducer
    },
});
export default store;