import React from "react";
import MatchComparisonProgressBar from "./MatchComparisonProgressBar";
import { useSelector } from "react-redux";

const MatchComparison = ({ teamStatistics }) => {
  const { lang } = useSelector((state) => state.langSlice);
  return (
    <>
      <MatchComparisonProgressBar
        percentage1={teamStatistics?.homeTeamTotalShotsPer}
        percentage2={teamStatistics?.awayTeamTotalShotsPer}
        value1={
          teamStatistics?.homeTeamTotalShots != null
            ? teamStatistics?.homeTeamTotalShots
            : 0
        }
        value2={
          teamStatistics?.awayTeamTotalShots != null
            ? teamStatistics?.awayTeamTotalShots
            : 0
        }
        name={
          lang == "english"
            ? "Total Shots"
            : lang == "spanish"
            ? "Disparos totales"
            : lang == "french"
            ? "Nombre total de tirs"
            : lang == "arabic"
            ? "مجموع الطلقات"
            : "Total Shots"
        }
      />
      <MatchComparisonProgressBar
        percentage1={teamStatistics?.homeTeamShotsOnGoalPer}
        percentage2={teamStatistics?.awayTeamShotsOnGoalPer}
        value1={
          teamStatistics?.homeTeamShotsOnGoal != null
            ? teamStatistics?.homeTeamShotsOnGoal
            : 0
        }
        value2={
          teamStatistics?.awayTeamShotsOnGoal != null
            ? teamStatistics?.awayTeamShotsOnGoal
            : 0
        }
        name={
          lang == "english"
            ? "Shots On Goal"
            : lang == "spanish"
            ? "Tiros a puerta"
            : lang == "french"
            ? "Tirs au but"
            : lang == "arabic"
            ? "تسديدة على المرمى"
            : "Shots On Goal"
        }
      />
      <MatchComparisonProgressBar
        percentage1={teamStatistics?.homeTeamShotsOffGoalPer}
        percentage2={teamStatistics?.awayTeamShotsOffGoalPer}
        value1={
          teamStatistics?.homeTeamShotsOffGoal != null
            ? teamStatistics?.homeTeamShotsOffGoal
            : 0
        }
        value2={
          teamStatistics?.awayTeamShotsOffGoal != null
            ? teamStatistics?.awayTeamShotsOffGoal
            : 0
        }
        name={
          lang == "english"
            ? "Shots Off Goal"
            : lang == "spanish"
            ? "Tiros fuera de portería"
            : lang == "french"
            ? "Tirs hors but"
            : lang == "arabic"
            ? "التسديدات خارج المرمى"
            : "Shots Off Goal"
        }
      />
      <MatchComparisonProgressBar
        percentage1={teamStatistics?.homeTeamFoulsPer}
        percentage2={teamStatistics?.awayTeamFoulsPer}
        value1={
          teamStatistics?.homeTeamFouls != null
            ? teamStatistics?.homeTeamFouls
            : 0
        }
        value2={
          teamStatistics?.awayTeamFouls != null
            ? teamStatistics?.awayTeamFouls
            : 0
        }
        name={
          lang == "english"
            ? "Fouls"
            : lang == "spanish"
            ? "Faltas"
            : lang == "french"
            ? "Fautes"
            : lang == "arabic"
            ? "الأخطاء"
            : "Fouls"
        }
      />
      <MatchComparisonProgressBar
        percentage1={teamStatistics?.homeTeamCornerPer}
        percentage2={teamStatistics?.awayTeamCornerPer}
        value1={
          teamStatistics?.homeTeamCorner != null
            ? teamStatistics?.homeTeamCorner
            : 0
        }
        value2={
          teamStatistics?.awayTeamCorner != null
            ? teamStatistics?.awayTeamCorner
            : 0
        }
        name={
          lang == "english"
            ? "Corner Kicks"
            : lang == "spanish"
            ? "Tiros de esquina"
            : lang == "french"
            ? "Coups de pied de coin"
            : lang == "arabic"
            ? "ركن الركلات"
            : "Corner Kicks"
        }
      />
      <MatchComparisonProgressBar
        percentage1={teamStatistics?.homeTeamOffsidesPer}
        percentage2={teamStatistics?.awayTeamOffsidesPer}
        value1={
          teamStatistics?.homeTeamOffsides != null
            ? teamStatistics?.homeTeamOffsides
            : 0
        }
        value2={
          teamStatistics?.awayTeamOffsides != null
            ? teamStatistics?.awayTeamOffsides
            : 0
        }
        name={
          lang == "english"
            ? "Offsides"
            : lang == "spanish"
            ? "Fueras de juego"
            : lang == "french"
            ? "Hors-jeu"
            : lang == "arabic"
            ? "التسلل"
            : "Offsides"
        }
      />

      <MatchComparisonProgressBar
        percentage1={teamStatistics?.homeTeamYellowCardPer}
        percentage2={teamStatistics?.awayTeamYellowCardPer}
        value1={
          teamStatistics?.homeTeamYellowCard != null
            ? teamStatistics?.homeTeamYellowCard
            : 0
        }
        value2={
          teamStatistics?.awayTeamYellowCard != null
            ? teamStatistics?.awayTeamYellowCard
            : 0
        }
        name={
          lang == "english"
            ? "Yellow Cards"
            : lang == "spanish"
            ? "Tarjetas amarillas"
            : lang == "french"
            ? "Cartons jaunes"
            : lang == "arabic"
            ? "البطاقات الصفراء"
            : "Yellow Cards"
        }
      />
      <MatchComparisonProgressBar
        percentage1={teamStatistics?.homeTeamRedCardPer}
        percentage2={teamStatistics?.awayTeamRedCardPer}
        value1={
          teamStatistics?.homeTeamRedCard != null
            ? teamStatistics?.homeTeamRedCard
            : 0
        }
        value2={
          teamStatistics?.awayTeamRedCard != null
            ? teamStatistics?.awayTeamRedCard
            : 0
        }
        name={
          lang == "english"
            ? "Red Cards"
            : lang == "spanish"
            ? "Tarjetas rojas"
            : lang == "french"
            ? "Cartons rouges"
            : lang == "arabic"
            ? "البطاقات الحمراء"
            : "Red Cards"
        }
      />
      <MatchComparisonProgressBar
        percentage1={teamStatistics?.homeTeamPossession.replace("%", "")}
        percentage2={teamStatistics?.awayTeamPossession.replace("%", "")}
        value1={
          teamStatistics?.homeTeamPossession != null
            ? teamStatistics?.homeTeamPossession
            : 0
        }
        value2={
          teamStatistics?.awayTeamPossession != null
            ? teamStatistics?.awayTeamPossession
            : 0
        }
        name={
          lang == "english"
            ? "Ball Possession"
            : lang == "spanish"
            ? "Posesión del balón"
            : lang == "french"
            ? "Possession de la balle"
            : lang == "arabic"
            ? "امتلاك الكرة"
            : "Ball Possession"
        }
      />
      <MatchComparisonProgressBar
        percentage1={teamStatistics?.homeTeamPassesPer}
        percentage2={teamStatistics?.awayTeamPassesPer}
        value1={
          teamStatistics?.homeTeamPasses != null
            ? teamStatistics?.homeTeamPasses
            : 0
        }
        value2={
          teamStatistics?.awayTeamPasses != null
            ? teamStatistics?.awayTeamPasses
            : 0
        }
        name={
          lang == "english"
            ? "Passes"
            : lang == "spanish"
            ? "Pases"
            : lang == "french"
            ? "Laissez-passer"
            : lang == "arabic"
            ? "يمر، يمرر، اجتاز بنجاح"
            : "Passes"
        }
      />
    </>
  );
};

export default MatchComparison;
