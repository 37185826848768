import React from 'react';
import classes from "../css/Loader.module.css";
import loading from "../animations/loading.json";
import LottieAnimation from "lottie-react";

const Loader = () => {
  return (
    <div className={classes.loader_container}>
        <div className={classes.loader}>
            <LottieAnimation className={classes.loader_animation} animationData={loading} autoplay={true} loop={true} />
        </div>
    </div>
  )
}

export default Loader;